/* DashboardScreen.css */

.dashboard-container {
    font-family: Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbar {
    background-color: #333;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1rem;
}

.nav-items li {
    margin: 0;
}

.nav-items a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.nav-items a:hover {
    background-color: #575757;
}

.logout-button {
    background-color: #f44336;
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #d32f2f;
}

.dashboard-content {
    flex: 1;
    padding: 2rem;
    background-color: #f4f4f4;
}
